import gql from "graphql-tag"

import fields from "./fields"

const typeList = {
  CollectionPage: function() {
    return {
      fieldname: "collectionPage",
      query: gql`
        fragment CollectionPageFields on CollectionPage {
          ${fields.entryDefault}
          name
          title
          titleImage
          titleSecondary
          subtitle {
            ${fields.richText}
          }
          textColour
          slug
          collectionItemsCollection {
            items {
              ${fields.entryDefault}
            }
          }
          sectionsCollection(limit: 50) {
            items {
              ${fields.entryDefault}
            }
          }
          showProductBackground
          productBackgroundImage
          background
          linkImage
          linkTitle
          metaTitle
          metaDescription
        }
      `
    }
  },

  EditorialSection: function() {
    return {
      fieldname: "editorialSection",
      query: gql`
      fragment EditorialSectionFields on EditorialSection {
        ${fields.entryDefault}
        name
        backgroundMedia
        backgroundColor
        textColor
        componentsCollection {
          items {
            ${fields.entryDefault}
          }
        }
        appearance
        containerLibrary
      }
      `
    }
  },

  FaqEntry: function() {
    return {
      fieldname: "faqEntry",
      query: gql`
      fragment FaqEntryFields on FaqEntry {
        name
        title
        text {
          ${fields.richText}
        }
      }
    `
    }
  },

  FeaturedReview: function() {
    return {
      fieldname: "featuredReview",
      query: gql`
        fragment FeaturedReviewFields on FeaturedReview {
          ${fields.entryDefault}
          title
          body {
            json
          }
          author
          rating
          product {
            ${fields.entryDefault}
          }
          images
        }
      `
    }
  },

  Footer: function() {
    return {
      fieldname: "footer",
      query: gql`
        fragment FooterFields on Footer {
          ${fields.entryDefault}
          name
          instagramUrl
          facebookUrl
          twitterUrl
          youtubeUrl
          tikTokUrl
          socialHeaderText
          newsletterHeaderText
          newsletterCtaText
          menu1Header
          menu1LinksCollection(limit: 20) {
            items {
              ${fields.entryDefault}
              title
              externalLink
              internalLink {
                ${fields.entryDefault}
                ...on CollectionPage {
                  slug
                }
                ... on Page {
                  slug
                }
                ... on Product {
                  slug
                }
                ... on ProductListing {
                  slug
                }
              }
            }
          }
          menu2Header
          menu2LinksCollection(limit: 20) {
            items {
              ${fields.entryDefault}
              title
              externalLink
              internalLink {
                ${fields.entryDefault}
                ...on CollectionPage {
                  slug
                }
                ... on Page {
                  slug
                }
                ... on Product {
                  slug
                }
                ... on ProductListing {
                  slug
                }
              }
            }
          }
          menu3Header
          menu3LinksCollection(limit: 20) {
            items {
              ${fields.entryDefault}
              title
              externalLink
              internalLink {
                ${fields.entryDefault}
                ...on CollectionPage {
                  slug
                }
                ... on Page {
                  slug
                }
                ... on Product {
                  slug
                }
                ... on ProductListing {
                  slug
                }
              }
            }
          }
        }
      `
    }
  },

  Gallery: function() {
    return {
      fieldname: "gallery",
      query: gql`
        ${this.MediaItem().query}
        ${this.UgcCollection().query}
        fragment GalleryFields on Gallery {
          ${fields.entryDefault}
          name
          elementId
          appearance
          title
          titleImage
          subtitle
          bodyText {
            json
          }
          textColor
          itemsCollection(limit:30) {
            items {
              ${fields.entryDefault}
              ... on CollectionPage {
                linkImage
                linkTitle
                slug
              }
              ... on MediaItem {
                ...MediaItemFields
              }
              ... on UgcCollection {
                ...UgcCollectionFields
              }
            }
          }
          itemTextColor
          uiColor
          ctaText
          ctaLink
          textAlignment
          backgroundImage
          backgroundColor
        }
      `
    }
  },

  GiftCardPage: function() {
    return {
      fieldname: "giftCardPage",
      query: gql`
        fragment GiftCardPageFields on GiftCardPage {
          ${fields.entryDefault}
          name
          pageTitle
          pageSubtitle
          textureBackground
          images
          title
          subtitle
          content {
            ${fields.richText}
          }
          relatedProductsCollection(limit: 5) {
            items {
              ${fields.entryDefault}
            }
          }
          deliveryInstructions {
            ${fields.richText}
          }
          metaTitle
          metaDescription
          giftCardsCollection(limit: 10) {
            items {
              ${fields.entryDefault}
            }
          }
          defaultCard {
            ${fields.entryDefault}
          }
          yotpoGalleryId
          termsAndConditions {
            ${fields.richText}
          }
          recommendedTitle
          recommendedProductsCollection(limit: 5) {
            items {
              ${fields.entryDefault}
            }
          }
        }
      `
    }
  },

  Group: function() {
    return {
      fieldname: "group",
      query: gql`
        fragment GroupFields on Group {
          ${fields.entryDefault}
          name
          itemsCollection(limit: 30) {
            items {
              ${fields.entryDefault}
            }
          }
          slug
        }
      `
    }
  },

  HairState: function() {
    return {
      fieldname: "hairState",
      query: gql`
        fragment HairStateFields on HairState {
          ${fields.entryDefault}
          title
          attributesCollection(limit: 20) {
            items {
              ${fields.entryDefault}
              attributeId
              name
              valuesCollection {
                items {
                  ${fields.entryDefault}
                  indexKey
                  valueId
                  name
                  image
                }
              }
            }
          }
          bleachSuitableQuantities
          colourToneMaintain
          howToGuide
        }
      `
    }
  },

  HowToGuide: function() {
    return {
      fieldname: "howToGuide",
      query: gql`
        ${this.HowToStep().query}
        fragment HowToGuideFields on HowToGuide {
          ${fields.entryDefault}
          name
          displayTitle
          intro
          metaTitle
          metaDescription
          featuredImage
          image
          productBackgroundImage
          requiredProduct {
            ${fields.entryDefault}
          }
          requiredProductsCollection(limit: 10) {
            items {
              ${fields.entryDefault}
            }
          }
          stepsCollection(limit: 30) {
            items {
              ... on HowToStep {
                ...HowToStepFields
              }
            }
          }
          faqCollection(limit: 20) {
            items {
              ${fields.entryDefault}
            }
          }
        }
      `
    }
  },

  HowToStep: function() {
    return {
      fieldname: "howToStep",
      query: gql`
        fragment HowToStepFields on HowToStep {
          ${fields.entryDefault}
          name
          isIndexStep
          title
          description {
            ${fields.richText}
          }
          image
        }
      `
    }
  },

  MediaItem: function() {
    return {
      fieldname: "mediaItem",
      query: gql`
        fragment MediaItemFields on MediaItem {
          elementId
          image
          altText
          title
          subtitle
          body {
            json
          }
          cta
          linkUrl
          fixedPosition
          sys {
            id
          }
        }
      `
    }
  },

  Menu: function() {
    return {
      fieldname: "menu",
      query: gql`
        ${this.MenuItem().query}
        fragment MenuFields on Menu {
          ${fields.entryDefault}
          name
          displayTitle
          menuItemsCollection (limit: 20) {
            items {
              ...MenuItemFields
            }
          }
        }
      `
    }
  },

  MenuItem: function() {
    return {
      fieldname: "menuItem",
      query: gql`
      fragment MenuItemFields on MenuItem {
        ${fields.entryDefault}
        name
        title
        externalLink
        internalLink {
          ${fields.entryDefault}
        }
        subMenu {
          ${fields.entryDefault}
        }
        image
      }
      `
    }
  },

  NavEntry: function(recursiveDepth = 0) {
    // NB - Because this model can request itself, a limit is set to prevent infinite recursion
    const maxDepth = 1
    return {
      fieldname: "navEntry",
      query: gql`
        ${this.MediaItem().query}
        ${this.MenuItem().query}
        ${
          recursiveDepth < maxDepth
            ? this.NavEntry(recursiveDepth + 1).query
            : ""
        }
        fragment NavEntryFields${
          recursiveDepth > 0 ? recursiveDepth.toString() : ""
        } on NavEntry {
          ${fields.entryDefault}
          name
          title
          subtitle
          image
          itemsCollection(limit: 10) {
            items {
              ...MenuItemFields
              ${
                recursiveDepth < maxDepth
                  ? `...NavEntryFields${(recursiveDepth + 1).toString()}`
                  : ""
              }
            }
          }
          favourites {
            ${fields.entryDefault}
            itemsCollection(limit: 20) {
              items {
                ${fields.entryDefault}
                ... on CollectionPage {
                  linkImage
                  linkTitle
                  slug
                }
              }
            }
          }
          promo1Title
          promo1Reference {
            ${fields.entryDefault}
            ... on MediaItem {
              ...MediaItemFields
            }
            ... on Group {
              itemsCollection(limit: 20) {
                items {
                  ${fields.entryDefault}
                  ... on CollectionPage {
                    linkImage
                    linkTitle
                    slug
                  }
                }
              }
            }
          }
          promo1Appearance
          promo2Title
          promo2Reference {
            ${fields.entryDefault}
            ... on MediaItem {
              ...MediaItemFields
            }
            ... on Group {
              itemsCollection(limit: 20) {
                items {
                  ${fields.entryDefault}
                  ... on CollectionPage {
                    linkImage
                    linkTitle
                    slug
                  }
                }
              }
            }
          }
          promo3Title
          promo3Reference {
            ${fields.entryDefault}
            ... on MediaItem {
              ...MediaItemFields
            }
            ... on Group {
              itemsCollection(limit: 20) {
                items {
                  ${fields.entryDefault}
                  ... on CollectionPage {
                    linkImage
                    linkTitle
                    slug
                  }
                }
              }
            }
          }
        }
      `
    }
  },

  Page: function({ deep = true } = {}) {
    const includedModels = gql`
      ${this.Gallery().query}
      ${this.UiBanner().query}
      ${this.UiHeader().query}
      ${this.HowToGuide().query}
      ${this.EditorialSection().query}
      ${this.RichTextSection().query}
    `
    return {
      fieldname: "page",
      query: gql`
        ${deep ? includedModels : ""}
        fragment PageFields on Page {
          ${fields.entryDefault}
          name
          title
          subtitle
          image
          slugPrefix
          slug
          metaTitle
          metaDescription
          noIndex
          searchTags
          sectionsCollection(limit: 30) {
            items {
            ${
              deep
                ? `
              ... on Gallery {
                ...GalleryFields
              }
              ... on UiBanner {
                ...UiBannerFields
              }
              ... on UiHeader {
                ...UiHeaderFields
              }
              ... on HowToGuide {
                ...HowToGuideFields
              }
              ... on EditorialSection {
                ...EditorialSectionFields
              }
              ... on RichTextSection {
                ...RichTextSectionFields
              }`
                : fields.entryDefault
            }
            }
          }
        }
      `
    }
  },

  Product: function() {
    return {
      fieldname: "product",
      query: gql`
        fragment ProductFields on Product {
          ${fields.entryDefault}
          name
          packshot
          activeThisStore
          showOnStorefront
          shortTitle
          previewDescription
          thumbnailImage
          productVariantsCollection(limit: 1) {
            items {
              ... on ProductVariant {
                sku
              }
            }
          }
          newProduct
          retailerLinks
          range
          stage
          badge {
            title
            colour
          }
          heroBackground
          images
          inlineImage1
          inlineImage2
          inlineImage3
          howToGallery {
            ${fields.entryDefault}
          }
          featuredBanner {
            ${fields.entryDefault}
          }
          instruction {
            ${fields.richText}
          }
          instructionMedia
          aftercare {
            ${fields.richText}
          }
          recommendation {
            ${fields.richText}
          }
          faqsCollection(limit: 10) {
            items {
              ${fields.entryDefault}
            }
          }
          ingredients {
            ${fields.richText}
          }
          complementaryProductsCollection(limit: 3) {
            items {
              ${fields.entryDefault}
            }
          }
          purpose {
            ${fields.richText}
          }
          suitability {
            ${fields.richText}
          }
          contents {
            ${fields.richText}
          }
          coverage {
            ${fields.richText}
          }
          setsUpsellCollection(limit: 5) {
            items {
              ${fields.entryDefault}
            }
          }
          waitlist
          yotpoGalleryId
        }
      `
    }
  },

  ProductBadge: function() {
    return {
      fieldname: "productBadge",
      query: gql`
        fragment ProductBadgeFields on ProductBadge {
          title
          colour
        }
      `
    }
  },

  ProductBundle: function() {
    return {
      fieldname: "productBundle",
      query: gql`
        fragment ProductBundleFields on ProductBundle {
          ${fields.entryDefault}
          name
          packshot
          activeThisStore
          shortTitle
          previewDescription
          thumbnailImage
          heroBackground
          range
          badge {
            title
            colour
          }
          featuredBanner {
            ${fields.entryDefault}
          }
          images
          inlineImage1
          inlineImage2
          inlineImage3
          waitlist
          whyThisBundle {
            ${fields.richText}
          }
          suggestedProductsCollection(limit: 5) {
            items {
              ${fields.entryDefault}
            }
          }
        }
      `
    }
  },

  ProductCategory: function() {
    return {
      fieldname: "productCategory",
      query: gql`
        ${this.Group().query}
        fragment ProductCategoryFields on ProductCategory {
          ${fields.entryDefault}
          name
          title
          description {
            ${fields.richText}
          }
          selectedProductsCollection(limit: 10) {
            items {
              ... on Group {
                ...GroupFields
              }
              ${fields.entryDefault}
            }
          }
          headerBackgroundImage
          stageId
          slug
        }
      `
    }
  },

  ProductGiftCard: function() {
    return {
      fieldname: "productGiftCard",
      query: gql`
        fragment ProductGiftCardFields on ProductGiftCard {
          ${fields.entryDefault}
          activeThisStore
          name
          description
          images
        }
      `
    }
  },

  ProductListing: function() {
    return {
      fieldname: "productListing",
      query: gql`
        ${this.Gallery().query}
        ${this.RichTextSection().query}
        ${this.UiBanner().query}
        fragment ProductListingFields on ProductListing {
          ${fields.entryDefault}
          name
          title
          sectionsCollection(limit: 15) {
            items {
              ... on Gallery {
                ...GalleryFields
              }
              ... on ProductCategory {
                ${fields.entryDefault}
                name
                title
                stageId
                slug
              }
              ... on RichTextSection {
                ...RichTextSectionFields
              }
              ... on UiBanner {
                ...UiBannerFields
              }
            }
          }
          slug
          metaTitle
          metaDescription
        }
      `
    }
  },

  Promotion: function() {
    return {
      fieldname: "promotion",
      query: gql`
        fragment PromotionFields on Promotion {
          ${fields.entryDefault}
          name
          promotionType
          matchProperty
          matchList
          threshold
          matchQuery
          promotionalProductsCollection(limit: 5) {
            items {
              ... on Product {
                ${fields.entryDefault}
              }
            }
          }
          promotionalMessage
          mystery
          title
          description
          icon
          message
          messageQualified
          discountType
          discountValue
          matchProductsCollection(limit: 5) {
            items {
              ${fields.entryDefault}
              ... on Group {
                itemsCollection(limit: 30) {
                  items {
                    ${fields.entryDefault}
                  }
                }
              }
            }
          }
        }
      `
    }
  },

  RichTextSection: function() {
    return {
      fieldname: "richTextSection",
      query: gql`
        fragment RichTextSectionFields on RichTextSection {
          ${fields.entryDefault}
          name
          appearance
          backgroundColor
          textContent {
            ${fields.richText}
          }
          textColor
          textAlignment
          image
          imageLink
        }
      `
    }
  },

  Salon: function() {
    return {
      fieldname: "salon",
      query: gql`
        fragment SalonFields on Salon {
          ${fields.entryDefault}
          name
          title
          subtitle
          featuredImage
          phoneNumber
          postcode
          address
          email
          schedule {
            ${fields.richText}
          }
          mapCoordinates {
            lat
            lon
          }
          bookingPortalUrl
        }
      `
    }
  },

  SalonData: function() {
    return {
      fieldname: "salonData",
      query: gql`
        fragment SalonDataFields on SalonData {
          ${fields.entryDefault}
          name
          salonsCollection {
            items {
              ${fields.entryDefault}
            }
          }
          pricingTitle
          pricingSubtitle
          pricingSubtitleMobile
          pricingTable
        }
      `
    }
  },

  SiteBanner: function() {
    return {
      fieldname: "siteBanner",
      query: gql`
        fragment SiteBannerFields on SiteBanner {
          ${fields.entryDefault}
          name
          textLine1 {
            ${fields.richText}
          }
          textLine2 {
            ${fields.richText}
          }
          backgroundColor
          enableScrollingText
        }
      `
    }
  },

  SiteConfig: function() {
    return {
      fieldname: "siteConfig",
      query: gql`
        ${this.SiteBanner().query}
        ${this.MediaItem().query}
        ${this.Menu().query}
        ${this.Footer().query}
        ${this.Promotion().query}
        fragment SiteConfigFields on SiteConfig {
          name
          mainMenu {
            ${fields.entryDefault}
          }
          siteBanner {
            ...SiteBannerFields
          }
          homePage {
            ${fields.entryDefault}
          }
          giftCardPage {
            ${fields.entryDefault}
          }
          footer {
            ...FooterFields
          }
          promotion {
            ...PromotionFields
          }
          promotionsCollection(limit: 10) {
            items {
              ...PromotionFields
            }
          }
          accountMenu {
            ...MenuFields
          }
          saleCouponCode
          saleAdjustmentValue
          itemQuantityLimit
          currency
          freeShippingThreshold
          cartAddOnProductListCollection(limit: 5) {
            items {
              ${fields.entryDefault}
            }
          }
          animatedLogo
          pdpCarousel {
            ${fields.entryDefault}
          }
          pdpHowToGallery {
            ${fields.entryDefault}
          }
          forceHideProducts
          reviewsCategoriesCollection(limit: 10) {
            items {
              ${fields.entryDefault}
            }
          }
          searchSuggestionsCollection(limit: 5) {
            items {
              ...MediaItemFields
            }
          }
          showSellingPlans
          sys {
            id
          }
        }
      `
    }
  },

  SocialMediaPost: function() {
    return {
      fieldname: "socialMediaPost",
      query: gql`
        fragment SocialMediaPostFields on SocialMediaPost {
          ${fields.entryDefault}
          name
          embedType
          postId
        }
      `
    }
  },

  TextContent: function() {
    return {
      fieldname: "textContent",
      query: gql`
        fragment TextContentFields on TextContent {
          name
          title
          text {
            ${fields.richText}
          }
          sys {
            id
          }
        }
      `
    }
  },

  UgcCollection: function() {
    return {
      fieldname: "ugcCollection",
      query: gql`
        fragment UgcCollectionFields on UgcCollection {
          ${fields.entryDefault}
          name
          selectedPosts
        }
      `
    }
  },

  UiBanner: function() {
    return {
      fieldname: "uiBanner",
      query: gql`
        fragment UiBannerFields on UiBanner {
          ${fields.entryDefault}
          name
          appearance
          backgroundColor
          backgroundColorSecondary
          backgroundImage
          mainImageMobile
          mainImageTablet
          mainImageDesktop
          title
          titleColor
          subtitle
          bodyText {
            json
          }
          textColor
          textAlignment
          cta
          ctaStyle
          ctaColor
          linkUrl
        }
      `
    }
  },

  UiElement: function() {
    return {
      fieldname: "uiElement",
      query: gql`
        fragment UiElementFields on UiElement {
          ${fields.entryDefault}
          elementId
          text
        }
      `
    }
  },

  UiHeader: function() {
    return {
      fieldname: "uiHeader",
      query: gql`
        fragment UiHeaderFields on UiHeader {
          ${fields.entryDefault}
          name
          appearance
          title
          subtitle
          textColor
          backgroundColor
          image
        }
      `
    }
  }
}

export default (contentType, payload = null) => {
  if (typeList[contentType] && typeList[contentType] instanceof Function) {
    return payload ? typeList[contentType](payload) : typeList[contentType]()
  } else {
    throw new Error(`Bah! No type definition exists for ${contentType}`)
  }
}
